/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
$blue: #558cff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #eb5545 !default;
$orange: #fc9918 !default;
$yellow: #d2ad1b !default;
$green: #00ba00 !default;
$teal: #36b9cc !default;
$cyan: #5298c9 !default;
$gray-600: #6c757d !default;

/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;

/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
$theme-primary: #558cff !default;
$theme-secondary: #fc9918 !default;
$theme-danger: #eb5545 !default;
$theme-disabled: #c9c9c9 !default;
$theme-light-accent: #52b7fe !default;
$theme-medium-accent: #008cff !default;
$theme-dark-accent: #33475b !default;
$theme-logo-tinted: #558cff !default;
