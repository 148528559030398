/* ----------------------------------------------------------------------------
	Typography
----------------------------------------------------------------------------- */
h1,
h2,
h3,
h4,
h5 {
  // margin: 1.2em 0 0.5em 0;
  padding: 0;
  color: #133459;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  // line-height: 1.4;
}

a {
  color: $primary;
  text-decoration: none;
  outline: none !important;
}

a:hover,
a:active {
  text-decoration: underline;
  /*border-width: 0 0 1px 0; border-style: dotted;*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a.img:hover,
a.img:active {
  text-decoration: none;
  border: 0;
}

a[href^="tel:"] {
  color: inherit;
  border: 0;
  text-decoration: none;
}

// ul, ol { margin-left: 35px; margin-top: 1em; margin-bottom: 1em; }
// ul.nav, ol.nav { margin-left: 0; margin-top: 1em; margin-bottom: 1em; }
// h1 + ul, h2 + ul, h3 + ul, h4 + ul, h1 + ol, h2 + ol, h3 + ol, h4 + ol  { margin-top: 0; }
// ul { list-style: disc outside; }
// ol { list-style: decimal; }
// ul.check, ul.star, ul.carrot { margin-left: 15px; list-style: none outside; }
// ul.disc { list-style: disc outside; }
// ul.circle { list-style: circle outside; }
// ul.square { list-style: square outside; }
// ul.check li, ul.star li, ul.carrot li { padding: 0 0 0 22px; }
// ul.carrot li { padding: 0 0 0 16px; }
// ul.check > li:before, ul.star > li:before, ul.carrot > li:before { display: inline-block; margin-top: 7px; margin-left: -22px; width: 22px; content: "\e840"; height: inherit; color: #0099FF; font-family: 'icons'; font-size: 14px; vertical-align: top; font-weight: normal !important; }
// ul.star > li:before { content: "\e834"; }
// ul.carrot > li:before { margin-top: -1px; margin-left: -16px; width: 16px; content: "\00BB"; color: inherit; font-size: inherit; }
// ul.check li li, ul.star li li, ul.carrot li li { padding: 0; }
// ul ul, ul ol, ol ol, ol ul { margin: 5px 0 5px 25px; }
// ol ol { list-style: lower-alpha; }
// ol ul, ul ul { list-style: circle; }
// ul ol { list-style: decimal; }
// ul li, ol li { margin: 5px 0 5px 0; }
// dl dt { font-weight: bold; }
// #content, #main_content, .break_word { overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; }

/* ----- overrides ----- */
// .form-control:focus,
// .custom-select:focus {
//   box-shadow: none;
// }
b,
strong {
  font-weight: 700;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

input[type="number"] {
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// input:focus::placeholder {
//   color: #cccccc;
// }
.custom-control {
  &.is-invalid,
  &:invalid {
    .custom-control-input ~ .custom-control-label {
      color: var(--red);
    }

    .custom-control-input ~ .custom-control-label:before {
      border-color: var(--red);
    }

    .custom-control-input:focus ~ .custom-control-label:before {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }
}

.form-control {
  &:invalid:focus,
  &.is-invalid:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    background-image: none;
  }
}

.custom-select {
  &:invalid:focus,
  &.is-invalid:focus {
    border-color: #80bdff;
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  }
}

// custom bootstrap forms
.form-control,
.form-select {
  padding: 0.5rem 0.75rem;
  font-weight: 600;
  height: calc(2.75rem + 2px);
  // border-color: transparent;
  border-radius: 0.5rem;
  &::placeholder {
    color: #bebebe;
  }
  &:focus {
    box-shadow: none;
    padding: 0.5rem calc(0.75rem - 1px);
    border-width: 2px;
    border-color: lighten($theme-primary, 15%) !important;
    &::placeholder {
      color: #cccccc;
    }
  }
  &[multiple] {
    height: auto;
  }

  // &.is-valid {
  //   border-color: #28a745;
  //   background-size: calc(0.75em) calc(0.75em) !important;
  //   background-position: right calc(0.75rem) center;
  // }
  &:invalid,
  &.is-invalid {
    border-color: $danger;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) bottom 0.75rem;

    &:focus {
      box-shadow: none;
    }
  }
}
// .form-label {
//   color: #808080;
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
  background-color: transparent !important;
}

.form-check {
  padding-left: 2.2em;
  margin-bottom: 0.75rem;
  &:last-child {
    margin-bottom: 0;
  }
  .form-check-input {
    margin-left: -2.2em;
    margin-top: 0;
    height: 1.6em;
    width: 1.6em;
  }
  label {
    // display: block;
    // padding-left: 0.7rem;
    color: inherit;
    vertical-align: middle;
  }
  &.custom-radio,
  &.custom-check {
    // padding: 0.625rem 1rem;
    // padding-left: 2.85rem;
    // margin-bottom: 0;
    // border-width: 1px;
    // border-style: solid;
    // border-color: var(--bs-gray-400);
    // border-radius: 0.25rem;
    .form-check-input[type="radio"],
    .form-check-input[type="checkbox"] {
      // border-radius: 0.25rem;
      &:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        background-size: 85%;
      }
    }
    &.selected {
      border-color: $theme-primary;
    }
  }
  &.block-label {
    padding: 0;
    position: relative;
    .form-check-input[type="radio"],
    .form-check-input[type="checkbox"] {
      margin-left: 0;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(1rem, -50%);
    }
    label {
      cursor: pointer;
      padding: 0.75rem 1rem;
      padding-left: 3.5rem;
    }
    .form-check-input[type="radio"]:checked + label,
    .form-check-input[type="checkbox"]:checked + label {
      padding: calc(0.75rem - 1px) calc(1rem - 1px) !important;
      padding-left: calc(3.5rem - 1px) !important;
      border-width: 2px !important;
      border-color: $theme-primary !important;
      background-color: $light;
    }
  }
  &.radio-image,
  &.check-image {
    width: 60px;
    max-width: 60px;
    min-height: 60px;
    height: auto;
    padding: 0;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;
    position: relative;
    label {
      width: 100%;
      height: 100%;
      margin: 0;
    }
    .form-check-input[type="radio"],
    .form-check-input[type="checkbox"] {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
      background-color: $theme-primary;
      border: 0;
      box-shadow: none !important;
      // &:checked {
      //   display: block;
      //   border-radius: 50%;
      //   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
      //   background-size: 85%;
      // }
    }
    .form-check-input[type="radio"]:checked + label,
    .form-check-input[type="checkbox"]:checked + label {
      padding: calc(0.25rem - 1px) !important;
      border-width: 2px !important;
      border-color: $theme-primary !important;
    }
  }
}

.modal-content {
  border-radius: 1rem;
  padding: 0.25rem;
  .modal-header {
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
    border-bottom: 0;
  }
  .modal-body {
    padding-top: 0.25rem;
  }
  .btn-close {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }
}

/* ----------------------------------------------------------------------------
	Font Weights
----------------------------------------------------------------------------- */
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
/* ----------------------------------------------------------------------------
	Text Color
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: $theme-primary;
}
.text-theme-secondary {
  color: $theme-secondary;
}
.text-theme-danger {
  color: $theme-danger;
}
.text-theme-disabled {
  color: $theme-disabled;
}
.text-theme-light-accent {
  color: $theme-light-accent;
}
.text-theme-medium-accent {
  color: $theme-medium-accent;
}
.text-theme-dark-accent {
  color: $theme-dark-accent;
}
.text-theme-logo-tinted {
  color: $theme-logo-tinted;
}
/* ----------------------------------------------------------------------------
	App Buttons
----------------------------------------------------------------------------- */
.btn {
  svg {
    fill: currentColor;
  }
  &-no-outline {
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }
}
[class*="app-btn-"] {
  border-radius: 100px;
  min-width: 160px;
  height: 50px;
  font-weight: 600;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  svg {
    fill: currentColor;
  }
  &:disabled {
    background-color: $theme-disabled;
    color: #ffffff;
    border-color: transparent;
  }
}
.app-btn-primary-livechat {
  background-color: #fff;
  color: $theme-primary;
  border: 1px solid $theme-primary;
  min-width: auto !important;
  padding-right: 10px;
  height: 33px;
  &:hover {
    background-color: $theme-primary;
    color: #fff;
  }
  &:focus {
    border: 1px solid $theme-primary;
    color: $theme-primary;
    box-shadow: 0 0 0 0.25rem lighten($theme-primary, 20%);
  }
}
.app-btn-primary {
  background-color: $theme-primary;
  color: #fff;
  border: 1px solid $theme-primary;
  &:hover {
    background-color: #fff;
    color: $theme-primary;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem lighten($theme-primary, 30%);
  }
}

.app-btn-secondary {
  background-color: $theme-secondary;
  color: #fff;
  border: 1px solid $theme-secondary;
  &:hover {
    background-color: #fff;
    color: $theme-secondary;
    border: 1px solid $theme-secondary;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem lighten($theme-secondary, 20%);
  }
}
.app-btn-secondary-inverted {
  background-color: #fff;
  color: $theme-secondary;
  border: 1px solid $theme-secondary;
  &:hover {
    background-color: $theme-secondary;
    color: #fff;
  }
  &:focus {
    border: 1px solid $theme-secondary;
    color: $theme-secondary;
    box-shadow: 0 0 0 0.25rem lighten($theme-secondary, 20%);
  }
}
.app-btn-tertiary {
  background-color: #fff;
  color: $theme-primary;
  border: 1px solid $theme-primary;
  &:hover {
    background-color: $theme-primary;
    color: #fff;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem lighten($theme-primary, 20%);
  }
}
.btn-delete {
  background-color: #fff;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  svg {
    fill: currentColor;
    vertical-align: center;
  }
  &:hover {
    color: #fff;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem lighten($theme-danger, 20%);
  }
}

.btn-pill {
  border-radius: 100px;
}
.btn-circle {
  width: 160px !important;
  height: 160px !important;
  min-width: 160px !important;
  border-radius: 50%;
}
// .btn-next {
//   &:after {
//     content: "\e5cc";
//     font-family: "Material Symbols Outlined";
//     font-size: 24px;
//     vertical-align: middle;
//   }
// }
.shadow-sm {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05) !important;
}
.app-spinner {
  width: 6rem;
  height: 6rem;
  color: $theme-primary;
}
/* ----------------------------------------------------------------------------
	Cards
----------------------------------------------------------------------------- */
.card {
  border-radius: 0.5rem;
}
.accordion-wrap {
  border-radius: 0.75rem;
  background-color: #fff;
  overflow: hidden;
  .accordion {
    &.instructions {
      .accordion-button:not(.collapsed) {
        color: inherit;
        background-color: transparent;
        box-shadow: none;
      }
      ul {
        padding-left: 1rem;
        margin-bottom: 0;
      }
    }
  }
}
/* ----------------------------------------------------------------------------
	Defaults Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  [class*="app-btn-"] {
    min-width: 220px;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
