@import "colors";
@import "~bootstrap/scss/bootstrap";
@import "default";

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f7fa;
  color: #333333;
}

// This gets rid of an annoying error overlay that only appears during dev
:root {
  iframe#webpack-dev-server-client-overlay {
    display: none !important;
  }
}

$cursor-values: (
  "pointer": pointer,
);
@each $name, $value in $cursor-values {
  .c-#{$name} {
    cursor: $value;
  }
}

.img-logo {
  width: 90%;
  max-width: 300px;
}
#header {
  min-height: 13vw;
  background-color: #f6f7fa;
  .logo {
    width: 45%;
    max-width: 250px;
    .img-logo {
      width: 100%;
    }
  }
  .time-remaining {
    font-size: 0.8rem;
  }
}

#page {
  padding-top: calc(13vw + 1.5rem);
  padding-bottom: calc(90px + 1rem);
  height: 100%;
  overflow-y: auto;
}

.progress-sm {
  background-color: #f5f7fa;
  height: 6px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  .progress-bar {
    border-radius: 0 4px 4px 0;
  }
}

#survey-nav-wrap {
  padding-top: 1rem;
  padding-bottom: 8px;
  height: calc(80px + 1rem);
  background: rgba(245, 247, 250, 0.1);
  background: linear-gradient(
    180deg,
    rgba(245, 247, 250, 0) 0%,
    rgba(245, 247, 250, 0.6) 20%,
    rgba(245, 247, 250, 0.9) 60%,
    rgba(245, 247, 250, 1) 100%
  );
  // backdrop-filter: blur(10px);
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  // bottom: 8px; // height of the progress bar
  z-index: 1030;
}

.speedtest-result {
  max-width: 400px;
  .circle-icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: $theme-light-accent;
    color: #ffffff;
  }
}

.enable-gps-steps {
  text-align: center;
  .gps-step {
    padding: 0.25rem 1rem;
    background-color: #f5f7fa;
    border-radius: 50px;
    font-weight: 600;
  }
  .material-symbols-outlined {
    margin: 0.5rem 0;
    font-size: 0.8rem;
    display: block;
    color: #ccc;
  }
}

.map-display {
  width: 100%;
  height: 50vh;
  border-radius: 0.75rem;
}

.form-input-group {
  .form-question {
    padding-bottom: 2.5rem;
    //margin-bottom: 1.5rem;
    //border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: inherit;
    }
  }
}

.form-input-group,
.explain-img {
  border-radius: 0.75rem;
  max-width: 700px;
  // overflow: hidden;
}

.question-form-input {
  &:last-child {
    margin-bottom: 0.25rem !important;
  }
}

.img-box {
  border-radius: 0.75rem;
  background-color: #333;
  .btn-delete {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 100;
  }
}

.video-preview {
  video {
    border-radius: 0.75rem;
  }
  .btn-delete {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 100;
  }
}

.markdown {
  img {
    max-width: 100%;
    height: auto;
  }
  ul,
  ol {
    text-align: left;
  }
}

.video-btn-wrap {
  .btn-video {
    text-align: center;
    min-width: 100px;
  }
}

.separator {
  .text {
    padding: 0 1rem;
  }
  &:before,
  &:after {
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }
}

// start old CSS rules withou UI mockups

video {
  &.fullscreen {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    background-size: cover;
  }
}

.video-status-message {
  .recording-animation {
    height: 1rem;
    width: 1rem;
    animation-duration: 1.3s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
  }
}

.video-record-button {
  .video-record-icon {
    font-size: 5rem !important;
  }

  .video-record-icon-sm {
    font-size: 4rem !important;
  }

  .spinner-border {
    height: 4rem;
    width: 4rem;
  }
}

.multi-select-wrap {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 20px;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 98%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
  }
  .scroll-viewport {
    max-height: 260px;
    overflow-y: auto;
  }
}

.radio-select-wrap {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 20px;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 98%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
  }
  .scroll-viewport {
    max-height: 260px;
    overflow-y: auto;
  }
}

/* ----------------------------------------------------------------------------
	Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header {
    min-height: 0;
    max-height: 70px;
    .logo {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .time-remaining {
      font-size: 0.9rem;
    }
  }
  #page {
    padding-top: calc(70px + 1.5rem);
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .progress-sm {
    background-color: transparent;
    bottom: auto;
    top: 70px;
  }
  #page {
    padding-top: calc(70px + 1.5rem + 8px);
    padding-bottom: calc(90px + 2rem);
  }
  #survey-nav-wrap {
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    bottom: 0;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
